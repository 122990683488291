<template>
  <a-result
    status="warning"
    :title="$t('payment.disabledLink')"
  >
    <template #extra>
      <op-button @click="goHome">{{ $t('payment.disabledLink.back') }}</op-button>
    </template>
  </a-result>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },

  computed: {},

  methods: {
    goHome() {
      this.$router.replace({ path: '/' })
    },
  },
}
</script>
<style lang="less" scoped></style>
